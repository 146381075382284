.room-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  max-width: 1900px;
  margin: 0 auto;

  .room {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding: 16px;
    margin: 0;
    /*width: 100vw;
    height: 100vh;*/
    min-width: 400px;
    min-height: 250px;
    background-color: rgba(55, 31, 201, 0.47);
    border-radius: 5px;

  }
}
