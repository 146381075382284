

.interviewer-home .container {
  width: 100%;
  margin: 15% auto;
}
.interviewer-home .col-4 {
  width: 25%;
  float: left;
}

.interviewer-home h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .interviewer-home .container {
    width: 1100px;
    text-align: center;
  }
  .interviewer-home h1 {
    font-size: 58px;
  }
}
