.app-bar-fixed {
  right: auto !important;
  left: auto !important;
  max-width: var(--width-nav-bar);
  background: linear-gradient(45deg, var(--color-primary-dark) 20%, var(--color-primary) 90%);
  /*background: linear-gradient(45deg,#53b4ff 30%,#6b7ffe 90%);*/
}

.app-bar-fixed:hover {
  background: linear-gradient(45deg, #371fc9 20%, #0660c7 90%);
  text-shadow: 1px 1px 2px #686868;
}

.app-bar-title {
  text-decoration: none;
  font-size: 1.5rem !important;
}

.app-bar-menu-item {
  color: white !important;
}

.app-bar-text-length-calculator {
  position: absolute !important;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
  z-index: -10;
}

.app-bar-menu-item-hidden .nav-bar-icon-padding {
  margin: 0 5px;
}

.redirect-dialog-url {
  background-color: #e8e8e8;
  border-radius: 5px;
  color: black;
  font-family: monospace;
  font-size: 14pt;
  font-style: normal;
  margin-bottom: 16px;
  text-decoration: none;
  text-align: center;
  padding: 3px;
}

.nav-bar-icon {
  margin-right: 5px;
}

.app-bar-fixed:hover .nav-bar-icon {
  background-color: rgba(113, 113, 113, .3);
  border-radius: 50%;
}
