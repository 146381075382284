.gears {
  display: table;
  width: 450px;
  height: 200px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: #1976d2;
}

.gear:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  content: "";
  border-radius: 50%;
  background: white;
}

.gear:after {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 3;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid #1976d2;
  box-sizing: border-box;
  background: #3333ad;
}

.gear .bar {
  position: absolute;
  left: -10px;
  top: 50%;
  z-index: 0;
  width: 140px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: #1976d2;
}

.gear .bar:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  content: "";
  border-radius: 2px;
  background: white;
}

.gear .bar:nth-child(2) {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.gear .bar:nth-child(3) {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
}

.gear.two {
  animation: 3s anticlockwiseError linear infinite;
}

@keyframes anticlockwiseError {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-30deg);
  }
  40% {
    transform: rotate(-25deg);
  }
  60% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.table-col {
  color: white;
  font-size: 170px;
  display: table-cell;
  text-align: center;
}

.text-container {
  width: 450px;
  text-align: center;
}

.text-container h2, .text-container h3, .text-container p {
  text-align: center;
  line-height: normal;
}

.table-group {
  display: table;
  width: inherit;
}

.container {
  line-height: 100%;
  height: 100%;
}

.button-container {
  display: flex;
}

.button-container a {
  text-decoration: none;
}

.btn {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
  background-color: #49bdea;
  color: white;
  transition: background-color .3s;
  font-size: 1.1em;
}

.btn:hover, .btn:focus {
  background-color: #1f0f6c;
}

.btn > * {
  position: relative;
}

.btn span {
  display: block;
  padding: 12px 24px;
}

.error-message {
  padding: 40px 0;
}

.btn:active:before {
  width: 120%;
  padding-top: 120%;
  transition: width .2s ease-out, padding-top .2s ease-out;
}

.blinker {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
