.App {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*text-align: center;*/
    background: rgb(49, 49, 177);
    background: linear-gradient(
            0deg,
            rgba(49, 49, 177, 1) 0%,
            rgba(49, 49, 177, 1) 50%,
            rgba(49, 49, 177, 0.6) 100%
    );
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.invisible {
    visibility: hidden !important;
}

/* Scrollbar block */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #3f51b5 #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #3f51b5;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #1B00A3;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #0060BF;
}
