.auth {
  color: black;
}

.auth main {
  background-color: white;
  padding: 24px;
  border-radius: 3px;
}
.auth-err {
  color: red;
}
